import {
    parseServerDateTime,
    formatDateTime,
    stripEmptyString,
    formatDisplayDateTime,
    getBasicHours,
    getBasicDate
} from "../utils/Dates"
import { concatStringWithSeparator, restoreLineBreaks, stripControlCharacters, stripNull } from "../utils/Strings"
import { ServiceKeys } from "./ConfigDataState"
import {
    Category,
    Coupons,
    EventDate,
    Job,
    Link,
    NotificationTemplate,
    Media,
    News,
    Organization,
    Person,
    PrintCatalog,
    Product,
    Showrooms,
    Trademark,
    UploadResult,
    NewsType
} from "./ExhibitorDataTypes"
import { hasServiceKeys } from "../globalStates/ConfigDataState"
import { ReferenceDataState } from "./ReferenceDataState"
import branding from "../branding/branding"
import { getInitialDate } from "../containers/notificationTemplates/NotificationTemplatesSection"
import { Immutable } from "@hookstate/core"
import { SubelementPackage } from "./SubelementPackagesState"
import moment from "moment"

export function mapOrganization(data: any, serviceKeys: ServiceKeys, refDataState: ReferenceDataState): any {
    return {
        ...mapProfile(data),
        products: mapProducts(filterProducts(data.products, false, serviceKeys, refDataState)),
        productsForCollections: mapProducts(filterProducts(data.products, true, serviceKeys, refDataState)),
        trademarks: mapTrademarks(data.trademarks),
        exhibitorNewsList: mapNews(filterNews(data.newsList, "ORGANIZATION", serviceKeys.news || 0)),
        organizerNewsList: mapNews(filterNews(data.newsList, "EVENTORGANIZER")),
        storyNewsList: mapNews(filterNews(data.newsList, "STORY", serviceKeys.stories || 0)),
        curatedEventDates: mapEventDates(filterEventDates(data.eventDates, true)),
        nonCuratedEventDates: mapEventDates(filterEventDates(data.eventDates, false, serviceKeys.eventDates || 0)),
        showrooms: mapShorooms(data.showrooms),
        goodies: mapCoupons(data.goodies, false, data.notificationTemplates),
        notificationPromotions: mapCoupons(data.goodies, true, data.notificationTemplates),
        jobOffers: mapJobs(data.jobOffers),
        printCatalog: mapPrintCatalog(data.printCatalog, data),
        notificationTemplates: mapNotificationTemplates(data.notificationTemplates, data.goodies)
    }
}

function mapProfile(data: any) {
    return {
        ...data,
        description: restoreLineBreaks(data.description),
        descriptionDe: restoreLineBreaks(data.descriptionDe),
        teaser: restoreLineBreaks(data.teaser),
        teaserDe: restoreLineBreaks(data.teaserDe)
    }
}

function filterProducts(
    products: any[],
    forCollections: boolean,
    serviceKeys: ServiceKeys,
    refDataState: ReferenceDataState
): any[] {
    const collectionCategoriesRootId: string = branding.configuration.productsForCollectionsPage.collectionCategoriesRootId

    const useProductsForCollections = hasServiceKeys(serviceKeys.productsForCollections)

    if (useProductsForCollections) {
        return products.filter(
            (product: Product) =>
                product.categories.some(
                    (cat: Category) => refDataState.findRootByCategoryId(cat.id)?.id === collectionCategoriesRootId
                ) === forCollections
        )
    } else if (!useProductsForCollections && forCollections) {
        return []
    }

    return products
}

function mapProducts(products: any[]): Product[] {
    if (!products) return products
    return products.map((product) => {
        return {
            ...product,
            showFrom: mapDate(product.showFrom),
            showUntil: mapDate(product.showUntil),
            description: restoreLineBreaks(product.description),
            descriptionDe: restoreLineBreaks(product.descriptionDe),
            teaser: restoreLineBreaks(product.teaser),
            teaserDe: restoreLineBreaks(product.teaserDe),
            collection: " "
        }
    })
}

function mapTrademarks(trademarks: any[]): Trademark[] {
    if (!trademarks) return trademarks
    return trademarks.map((trademark) => {
        return {
            ...trademark,
            description: restoreLineBreaks(trademark.description),
            descriptionDe: restoreLineBreaks(trademark.descriptionDe),
            teaser: restoreLineBreaks(trademark.teaser),
            teaserDe: restoreLineBreaks(trademark.teaserDe)
        }
    })
}

function filterNews(newsList: any[], type: NewsType, serviceKey?: number): any[] {
    if (serviceKey && serviceKey === 0) return []

    return newsList.filter((item: News) => item.type === type)
}

function mapNews(newsList: any[]): News[] {
    if (!newsList) return newsList
    return newsList.map((news) => {
        return {
            ...news,
            date: mapDate(news.date as string),
            description: restoreLineBreaks(news.description),
            descriptionDe: restoreLineBreaks(news.descriptionDe),
            teaser: restoreLineBreaks(news.teaser),
            teaserDe: restoreLineBreaks(news.teaserDe),
            type: news.type
        }
    })
}

function filterEventDates(eventDates: any[], curated: boolean, serviceKey?: number): any[] {
    if (serviceKey && serviceKey === 0) return []

    return eventDates.filter((item: EventDate) => item.curated === curated)
}

function mapEventDates(eventDates: any[]): EventDate[] {
    if (!eventDates) return eventDates
    return eventDates.map((eventDate) => {
        return {
            ...eventDate,
            startdatetime: mapDate(removeTimezoneData(eventDate.startdatetime)),
            enddatetime: mapDate(removeTimezoneData(eventDate.enddatetime)),
            description: restoreLineBreaks(eventDate.description),
            descriptionDe: restoreLineBreaks(eventDate.descriptionDe),
            teaser: restoreLineBreaks(eventDate.teaser),
            teaserDe: restoreLineBreaks(eventDate.teaserDe),
            persons: mapPersonsOut(eventDate.persons),
            date: mapDate(eventDate.startdatetime),
            startTime: getBasicHours(mapDate(eventDate.startdatetime)),
            endTime: getBasicHours(mapDate(eventDate.enddatetime))
        }
    })
}

function mapShorooms(showrooms: any[]): Showrooms[] {
    if (!showrooms) return showrooms
    return showrooms.map((showroom) => {
        return {
            ...showroom,
            shortDescription: restoreLineBreaks(showroom.shortDescription),
            shortDescriptionDe: restoreLineBreaks(showroom.shortDescriptionDe)
        }
    })
}

function mapCoupons(coupons: any[], boundToNotification: boolean, notificationTemplates: any[]): Coupons[] {
    if (!coupons) return coupons

    let filteredCoupons = coupons

    if (boundToNotification) {
        filteredCoupons = notificationTemplates
            ? coupons.filter(
                  (coupon) =>
                      !(notificationTemplates.filter((notification) => notification.action.param === coupon.id).length === 0)
              )
            : []
    } else {
        filteredCoupons = notificationTemplates
            ? coupons.filter(
                  (coupon) => notificationTemplates.filter((notification) => notification.action.param === coupon.id).length === 0
              )
            : coupons
    }

    return filteredCoupons.map((coupon) => {
        return {
            ...coupon,
            validStart: mapDate(coupon.validStart),
            validEnd: mapDate(coupon.validEnd),
            descriptionTitle: restoreLineBreaks(coupon.descriptionTitle),
            descriptionTitleDe: restoreLineBreaks(coupon.descriptionTitleDe),
            descriptionLong: restoreLineBreaks(coupon.descriptionLong),
            descriptionLongDe: restoreLineBreaks(coupon.descriptionLongDe),
            descriptionShort: restoreLineBreaks(coupon.descriptionShort),
            descriptionShortDe: restoreLineBreaks(coupon.descriptionShortDe)
        }
    })
}

function mapJobs(jobs: any[]): Job[] {
    if (!jobs) return []
    return jobs.map((job) => {
        return {
            ...job,
            startdate: mapDate(job.startdate as string),
            updated: mapDate(job.updated as string),
            showFrom: mapDate(job.showFrom as string),
            showUntil: mapDate(job.showUntil as string),
            description: restoreLineBreaks(job.description),
            descriptionDe: restoreLineBreaks(job.descriptionDe),
            requirements: restoreLineBreaks(job.requirements),
            requirementsDe: restoreLineBreaks(job.requirementsDe),
            homeOffice: mapTripleState(job.homeOffice)
        }
    })
}

function mapPrintCatalog(printCatalog: any, organization: any): PrintCatalog | undefined {
    if (!printCatalog)
        printCatalog = {
            adPages: []
        }

    if (!printCatalog.name) {
        printCatalog.name = organization.name
        printCatalog.nameImported = true
    } else printCatalog.nameImported = false

    let [address1, address2, address3] = parseAddress(printCatalog.address)
    if (!printCatalog.address && !printCatalog.postcode && !printCatalog.city && !printCatalog.countryCode) {
        address1 = organization.address1
        address2 = organization.address2
        address3 = organization.address3
        printCatalog.postcode = organization.postCode
        printCatalog.city = organization.city
        printCatalog.countryCode = organization.countryCode
        printCatalog.addressImported = true
    } else printCatalog.addressImported = false

    if (!printCatalog.phone && !printCatalog.mobile && !printCatalog.fax && !printCatalog.email && !printCatalog.web) {
        printCatalog.phone = organization.phone
        printCatalog.mobile = organization.mobile
        printCatalog.fax = organization.fax
        printCatalog.email = organization.email
        printCatalog.web = organization.web
        printCatalog.contactImported = true
    } else printCatalog.contactImported = false

    if (!printCatalog.description && !printCatalog.descriptionDe) {
        printCatalog.description = organization.teaser
        printCatalog.descriptionDe = organization.teaserDe
        printCatalog.descriptionImported = true
    } else printCatalog.descriptionImported = false

    return {
        ...printCatalog,
        address: undefined,
        address1,
        address2,
        address3,
        description: restoreLineBreaks(printCatalog.description),
        descriptionDe: restoreLineBreaks(printCatalog.descriptionDe)
    }
}

function mapNotificationTemplates(notificationTemplates: any[], coupons: any[]): NotificationTemplate[] {
    if (!notificationTemplates) return []
    return notificationTemplates.map((template) => {
        return {
            ...template,
            id: template.id,
            subelementPackage: template.subelementPackage,
            text: template.text,
            trigger: template.trigger,
            date: getInitialDate(template.trigger),
            maxUsageCount: template.maxUsageCount,
            destinations: template.destinations,
            targetGroup: template.targetGroup,
            action: template.action,
            localized: template.localized,
            selector: {
                geofences: template.trigger.geofences || [],
                geofenceDestination: "",
                timeStart: template.trigger.timeFrameStart,
                timeEnd: template.trigger.timeFrameEnd,
                interests: template.targetGroup.interests,
                countriesFilter: [],
                questionFilters: []
            }
        }
    })
}

export function mapOrganizationOut(
    organization: Organization,
    serviceKeys: ServiceKeys,
    refDataState: ReferenceDataState,
    subelementPackages: Immutable<SubelementPackage[]>
): any {
    return {
        ...mapProfileOut(organization, serviceKeys),
        countryCode: organization.countryCode ? organization.countryCode : null,
        tags: hasServiceKeys(serviceKeys.keywords) ? organization.tags : undefined,
        categories: hasServiceKeys(serviceKeys.category) ? filterSynthetic(organization.categories, refDataState) : undefined,
        links: hasServiceKeys(serviceKeys.link) ? mapLinksOut(organization.links) : undefined,
        products: hasServiceKeys(serviceKeys.products)
            ? mapProductsOut(organization.products, serviceKeys, refDataState)
            : undefined,
        productsForCollections: hasServiceKeys(serviceKeys.productsForCollections)
            ? mapProductsOut(organization.productsForCollections, serviceKeys, refDataState)
            : undefined,
        trademarks: mapTrademarksOut(organization.trademarks, serviceKeys, refDataState),
        persons: mapPersonsOutControlled(organization.persons, serviceKeys),
        medias: hasServiceKeys(serviceKeys.multimedia) ? mapMediasOut(organization.medias) : undefined,
        newsList: mapNewsOut(organization, serviceKeys, refDataState),
        eventDates: mapEventDatesOut(organization, serviceKeys),
        showrooms: mapShoroomsOut(organization.showrooms),
        goodies: mapCouponsOut(organization, serviceKeys),
        jobOffers: hasServiceKeys(serviceKeys.jobAd) ? mapJobsOut(organization.jobOffers) : undefined,
        printCatalog: mapPrintCatalogOut(organization.printCatalog, serviceKeys, refDataState),
        notificationTemplates:
            hasServiceKeys(serviceKeys["notificationTemplate#small"]) ||
            hasServiceKeys(serviceKeys["notificationTemplate#medium"]) ||
            hasServiceKeys(serviceKeys["notificationTemplate#large"])
                ? mapNotificationTemplatesOut(organization.notificationTemplates, subelementPackages)
                : undefined
    }
}

function mapProfileOut(organization: Organization, serviceKeys: ServiceKeys) {
    return {
        ...organization,
        description: stripNull(stripControlCharacters(organization.description)),
        descriptionDe: stripNull(stripControlCharacters(organization.descriptionDe)),
        teaser: stripNull(stripControlCharacters(organization.teaser)),
        teaserDe: stripNull(stripControlCharacters(organization.teaserDe)),
        consentTitle: stripNull(stripControlCharacters(organization.consentTitle)),
        consentTitleDe: stripNull(stripControlCharacters(organization.consentTitleDe)),
        consentText: stripNull(stripControlCharacters(organization.consentText)),
        consentTextDe: stripNull(stripControlCharacters(organization.consentTextDe)),
        foundingYear: stripNull(stripControlCharacters(organization.foundingYear)),
        industry: stripNull(stripControlCharacters(organization.industry)),
        size: stripNull(stripControlCharacters(organization.size)),
        logoUrl: hasServiceKeys(serviceKeys.logo) ? organization.logoUrl : undefined,
        backgroundImageUrl: hasServiceKeys(serviceKeys.headerGraphic) ? organization.backgroundImageUrl : undefined,
        backgroundImageMobileUrl: hasServiceKeys(serviceKeys.headerGraphic) ? organization.backgroundImageMobileUrl : undefined,
        headerVideoImageUrl: hasServiceKeys(serviceKeys.headerVideo) ? organization.headerVideoImageUrl : undefined,
        headerVideoUrl: hasServiceKeys(serviceKeys.headerVideo) ? organization.headerVideoUrl : undefined,

        facebook: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.facebook))
                ? organization.facebook
                : ""
            : undefined,
        instagram: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.instagram))
                ? organization.instagram
                : ""
            : undefined,
        linkedIn: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.linkedIn))
                ? organization.linkedIn
                : ""
            : undefined,
        xing: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.xing))
                ? organization.xing
                : ""
            : undefined,
        twitter: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.twitter))
                ? organization.twitter
                : ""
            : undefined,
        youTube: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.youTube))
                ? organization.youTube
                : ""
            : undefined,
        pinterest: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.pinterest))
                ? organization.pinterest
                : ""
            : undefined,
        tiktok: hasServiceKeys(serviceKeys.socialMedia)
            ? stripNull(stripControlCharacters(organization.tiktok))
                ? organization.tiktok
                : ""
            : undefined,

        iframeUrl:
            hasServiceKeys(serviceKeys.iframe) && stripNull(stripControlCharacters(organization.iframeUrl))
                ? organization.iframeUrl
                : undefined,
        iframeUrlDe:
            hasServiceKeys(serviceKeys.iframe) && stripNull(stripControlCharacters(organization.iframeUrlDe))
                ? organization.iframeUrlDe
                : undefined
    }
}

function mapLinksOut(links: Link[]): any[] | undefined {
    return links.map((link) => {
        return {
            ...link,
            text: stripNull(stripControlCharacters(link.text)),
            textDe: stripNull(stripControlCharacters(link.textDe)),
            url: stripNull(stripControlCharacters(link.url)),
            urlDe: stripNull(stripControlCharacters(link.urlDe)),
            id: Number(link.id) <= 0 ? undefined : link.id
        }
    })
}

function mapProductsOut(products: Product[], serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
    return products.map((product) => {
        return {
            ...product,
            id: Number(product.id) <= 0 ? undefined : product.id,
            showFrom: product.showFrom && mapDisplayDateTimeOut(product.showFrom),
            showUntil: product.showUntil && mapDisplayDateTimeOut(product.showUntil),
            description: stripControlCharacters(product.description),
            descriptionDe: stripControlCharacters(product.descriptionDe),
            teaser: stripControlCharacters(product.teaser),
            teaserDe: stripControlCharacters(product.teaserDe),
            categories: hasServiceKeys(serviceKeys.category) ? filterSynthetic(product.categories, refDataState) : undefined,
            tags: hasServiceKeys(serviceKeys.keywords) ? product.tags : undefined,
            medias: mapMediasOut(product.medias),
            links: mapLinksOut(product.links),
            collection: undefined
        }
    })
}

function mapTrademarksOut(trademarks: Trademark[], serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
    const hasServiceKeyTrademarks = hasServiceKeys(serviceKeys.trademarks)
    if (hasServiceKeyTrademarks) {
        return trademarks.map((trademark) => {
            return {
                ...trademark,
                id: Number(trademark.id) <= 0 ? undefined : trademark.id,
                description: stripControlCharacters(trademark.description),
                descriptionDe: stripControlCharacters(trademark.descriptionDe),
                teaser: stripControlCharacters(trademark.teaser),
                teaserDe: stripControlCharacters(trademark.teaserDe),
                categories: hasServiceKeys(serviceKeys.category)
                    ? filterSynthetic(trademark.categories, refDataState)
                    : undefined,
                tags: hasServiceKeys(serviceKeys.keywords) ? trademark.tags : undefined,
                medias: mapMediasOut(trademark.medias)
            }
        })
    }
    return undefined
}

function mapPersonRefsOut(persons: Person[]) {
    return persons.map((person) => {
        return {
            id: Number(person.id) <= 0 ? undefined : person.id,
            function: person.function
        }
    })
}

function mapPersonsOut(persons: Person[]) {
    return persons.map((person) => {
        return {
            ...person,
            title: stripNull(stripControlCharacters(person.title)),
            firstName: stripNull(stripControlCharacters(person.firstName)),
            midName: stripNull(stripControlCharacters(person.midName)),
            lastName: stripNull(stripControlCharacters(person.lastName)),
            salutation: stripNull(stripControlCharacters(person.salutation)),
            company: stripNull(stripControlCharacters(person.company)),
            position: stripNull(stripControlCharacters(person.position)),
            positionDe: stripNull(stripControlCharacters(person.positionDe)),
            email: stripNull(stripControlCharacters(person.email)),
            phone: stripNull(stripControlCharacters(person.phone)),
            fax: stripNull(stripControlCharacters(person.fax)),
            web: stripNull(stripControlCharacters(person.web)),
            description: stripNull(stripControlCharacters(person.description)),
            descriptionDe: stripNull(stripControlCharacters(person.descriptionDe)),
            teaser: stripNull(stripControlCharacters(person.teaser)),
            teaserDe: stripNull(stripControlCharacters(person.teaserDe)),
            id: Number(person.id) <= 0 ? undefined : person.id
        }
    })
}

function mapPersonsOutControlled(persons: Person[], serviceKeys: ServiceKeys) {
    const hasServiceKeyContactPartner = hasServiceKeys(serviceKeys.contactPartner)
    if (hasServiceKeyContactPartner) {
        return mapPersonsOut(persons)
    }
    return undefined
}

function mapMediasOut(medias: Media[]) {
    return medias.map((media) => {
        const { contentType, name, size, url, ...mediaToKeep } = media
        return {
            ...mediaToKeep,
            id: Number(media.id) <= 0 ? undefined : media.id
        }
    })
}

function mapNewsOut(organization: Organization, serviceKeys: ServiceKeys, refDataState: ReferenceDataState): any[] | undefined {
    const hasServiceKeysExhibitorNews = hasServiceKeys(serviceKeys.news)
    const hasServiceKeysStories = hasServiceKeys(serviceKeys.news)

    const newsList = organization.exhibitorNewsList.concat(organization.organizerNewsList, organization.storyNewsList)

    if (hasServiceKeysExhibitorNews || hasServiceKeysStories) {
        return newsList.map((news) => {
            return {
                ...news,
                id: Number(news.id) <= 0 ? undefined : news.id,
                description: stripControlCharacters(news.description),
                descriptionDe: stripControlCharacters(news.descriptionDe),
                teaser: stripControlCharacters(news.teaser),
                teaserDe: stripControlCharacters(news.teaserDe),
                date: mapDisplayDateTimeOut(news.date),
                categories: hasServiceKeys(serviceKeys.category) ? filterSynthetic(news.categories, refDataState) : undefined,
                tags: hasServiceKeys(serviceKeys.keywords) ? news.tags : undefined,
                medias: mapMediasOut(news.medias),
                type: news.type
            }
        })
    }
    return undefined
}

function mapEventDatesOut(organization: Organization, serviceKeys: ServiceKeys) {
    const eventDates = organization.curatedEventDates.concat(organization.nonCuratedEventDates)

    const now = moment()
    const timezoneOffset = now.format("Z")

    return eventDates.map((eventDate) => {
        const startdatetime = `${getBasicDate(eventDate.date)}T${eventDate.startTime || "00:00"}:00${timezoneOffset}`
        const enddatetime = `${getBasicDate(eventDate.date)}T${eventDate.endTime || "00:00"}:00${timezoneOffset}`

        return {
            ...eventDate,
            id: Number(eventDate.id) <= 0 ? undefined : eventDate.id,
            description: stripControlCharacters(eventDate.description),
            descriptionDe: stripControlCharacters(eventDate.descriptionDe),
            teaser: stripControlCharacters(eventDate.teaser),
            teaserDe: stripControlCharacters(eventDate.teaserDe),
            tags: hasServiceKeys(serviceKeys.keywords) ? eventDate.tags : undefined,
            medias: mapMediasOut(eventDate.medias),
            persons: mapPersonRefsOut(eventDate.persons),
            startdatetime: startdatetime,
            enddatetime: enddatetime
        }
    })
}

function mapShoroomsOut(showrooms: Showrooms[]) {
    return showrooms.map((showroom) => {
        return {
            ...showroom,
            id: Number(showroom.id) <= 0 ? undefined : showroom.id,
            shortDescription: stripControlCharacters(showroom.shortDescription),
            shortDescriptionDe: stripControlCharacters(showroom.shortDescriptionDe)
        }
    })
}

function mapCouponsOut(organization: Organization, serviceKeys: ServiceKeys) {
    const hasServiceKeyGoodies = hasServiceKeys(serviceKeys.goodies)

    const hasServiceKeyNotificationTemplate =
        hasServiceKeys(serviceKeys["notificationTemplate#small"]) ||
        hasServiceKeys(serviceKeys["notificationTemplate#medium"]) ||
        hasServiceKeys(serviceKeys["notificationTemplate#large"])

    let couponsList = organization.goodies

    if (hasServiceKeyNotificationTemplate) {
        couponsList = couponsList.concat(organization.notificationPromotions)
    }

    if (hasServiceKeyGoodies || hasServiceKeyNotificationTemplate) {
        return couponsList.map((coupon) => {
            return {
                ...coupon,
                id: Number(coupon.id) <= 0 ? undefined : coupon.id,
                validStart: mapDisplayDateTimeOut(coupon.validStart),
                validEnd: mapDisplayDateTimeOut(coupon.validEnd),
                descriptionTpitle: stripControlCharacters(coupon.descriptionTitle),
                descriptionTitleDe: stripControlCharacters(coupon.descriptionTitleDe),
                descriptionLong: stripControlCharacters(coupon.descriptionLong),
                descriptionLongDe: stripControlCharacters(coupon.descriptionLongDe),
                descriptionShort: stripControlCharacters(coupon.descriptionShort),
                descriptionShortDe: stripControlCharacters(coupon.descriptionShortDe)
            }
        })
    }
    return undefined
}

function mapJobsOut(jobs: Job[]) {
    return jobs.map((job) => {
        return {
            ...job,
            id: Number(job.id) <= 0 ? undefined : job.id,
            description: stripNull(stripControlCharacters(job.description)),
            descriptionDe: stripNull(stripControlCharacters(job.descriptionDe)),
            requirements: stripNull(stripControlCharacters(job.requirements)),
            requirementsDe: stripNull(stripControlCharacters(job.requirementsDe)),
            startdate: stripEmptyString(mapDisplayDateTimeOut(job.startdate)),
            updated: mapDateTimeOut(job.updated),
            showFrom: stripEmptyString(mapDisplayDateTimeOut(job.showFrom)),
            showUntil: stripEmptyString(mapDisplayDateTimeOut(job.showUntil)),
            persons: mapPersonsOut(job.persons),
            medias: mapMediasOut(job.medias),
            links: mapLinksOut(job.links),
            homeOffice: mapTripleStateOut(job.homeOffice),
            location: stripNull(stripControlCharacters(job.location)),
            locationDe: stripNull(stripControlCharacters(job.locationDe)),
            employment: stripNull(stripControlCharacters(job.employment)),
            employmentDe: stripNull(stripControlCharacters(job.employmentDe)),
            careerLevel: stripNull(stripControlCharacters(job.careerLevel)),
            careerLevelDe: stripNull(stripControlCharacters(job.careerLevelDe)),
            salaryGroup: stripNull(stripControlCharacters(job.salaryGroup))
        }
    })
}

function mapNotificationTemplatesOut(
    notificationTemplates: NotificationTemplate[],
    subelementPackages: Immutable<SubelementPackage[]>
) {
    return notificationTemplates.map((template) => {
        const subelementPackage = subelementPackages.find((sp) => sp.id === template.subelementPackage)

        const criteriaLimit = subelementPackage?.subelementContingents["notificationTemplate.criteria"] || 0

        return {
            ...template,
            subelementPackage: template.subelementPackage,
            text: template.text,
            trigger: {
                type: template.trigger.type,
                geofences: template.trigger.geofences,
                timeFrameStart:
                    template.trigger.timeFrameStart && template.trigger.timeFrameStart?.length! > 0
                        ? template.trigger.timeFrameStart
                        : branding.configuration.notificationTemplatesPage.sctNotificationTemplates.showDateTimeSection
                        ? null
                        : `${branding.configuration.notificationTemplatesPage.sctNotificationTemplates.defaultStartDate}T00:00:00.00Z`,
                timeFrameEnd:
                    template.trigger.timeFrameEnd && template.trigger.timeFrameEnd?.length! > 0
                        ? template.trigger.timeFrameEnd
                        : branding.configuration.notificationTemplatesPage.sctNotificationTemplates.showDateTimeSection
                        ? null
                        : `${branding.configuration.notificationTemplatesPage.sctNotificationTemplates.defaultEndDate}T23:59:59.00Z`
            },
            maxUsageCount: template.maxUsageCount,
            destinations: template.destinations,
            targetGroup: {
                interests: criteriaLimit === 0 ? undefined : template.targetGroup.interests,
                countriesFilter: template.targetGroup.countriesFilter,
                questionFilters: template.targetGroup.questionFilters
            },
            action: template.action,
            localized: template.localized,
            id: Number(template.id) <= 0 ? undefined : template.id
        }
    })
}

function parseAddress(address: string): string[] {
    if (!address) return []
    return address.split("\n")
}

function composeAddress(printCatalog: PrintCatalog): string {
    let address = ""
    address = concatStringWithSeparator(address, printCatalog.address1, "\n")
    address = concatStringWithSeparator(address, printCatalog.address2, "\n")
    address = concatStringWithSeparator(address, printCatalog.address3, "\n")

    return address
}

function mapPrintCatalogOut(printCatalog: PrintCatalog, serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
    const printCatalogOut: any = {}

    const hasServiceKeyPrintLogo = hasServiceKeys(serviceKeys.printLogo)
    if (
        hasServiceKeyPrintLogo ||
        hasServiceKeys(serviceKeys.printLogoExhibitorDirectory) ||
        hasServiceKeys(serviceKeys.printLogoCategoryDirectory)
    ) {
        printCatalogOut.logoFileId = printCatalog.logoFileId
        printCatalogOut.logoUrl = printCatalog.logoUrl
    }

    const hasServiceKeyPrintOrganizationName = hasServiceKeys(serviceKeys.printOrganizationName)
    if (hasServiceKeyPrintOrganizationName) {
        printCatalogOut.name = printCatalog.nameImported ? null : printCatalog.name
    }

    const hasServiceKeyPrintAddress = hasServiceKeys(serviceKeys.printAddress)
    const hasServiceKeyPrintAddressCategoryDirectory = hasServiceKeys(serviceKeys.printAddressCategoryDirectory)
    if (hasServiceKeyPrintAddress || hasServiceKeyPrintAddressCategoryDirectory) {
        printCatalogOut.address = printCatalog.addressImported ? null : composeAddress(printCatalog)
        printCatalogOut.postcode = printCatalog.addressImported ? null : printCatalog.postcode
        printCatalogOut.city = printCatalog.addressImported ? null : printCatalog.city
        printCatalogOut.countryCode = printCatalog.addressImported ? null : printCatalog.countryCode
    }

    const hasServiceKeyPrintContact = hasServiceKeys(serviceKeys.printContact)
    const hasServiceKeyPrintContactCategoryDirectory = hasServiceKeys(serviceKeys.printContactCategoryDirectory)
    if (hasServiceKeyPrintContact || hasServiceKeyPrintContactCategoryDirectory) {
        printCatalogOut.phone = printCatalog.contactImported ? null : printCatalog.phone
        printCatalogOut.fax = printCatalog.contactImported ? null : printCatalog.fax
        printCatalogOut.email = printCatalog.contactImported ? null : printCatalog.email
        printCatalogOut.web = printCatalog.contactImported ? null : printCatalog.web
    }

    const hasServiceKeyPrintDescription = hasServiceKeys(serviceKeys.printDescription)
    if (hasServiceKeyPrintDescription) {
        printCatalogOut.description = printCatalog.descriptionImported ? null : printCatalog.description
        printCatalogOut.descriptionDe = printCatalog.descriptionImported ? null : printCatalog.descriptionDe
    }

    const hasServiceKeyPrintAd = hasServiceKeys(serviceKeys.printAd)
    if (hasServiceKeyPrintAd) {
        printCatalogOut.adPages =
            printCatalog.adPages &&
            printCatalog.adPages.map((adPage) => ({
                ...adPage,
                id: Number(adPage.id) <= 0 ? undefined : adPage.id,
                name: undefined,
                adUrl: undefined
            }))
    }

    return {
        id: Number(printCatalog.id) <= 0 ? undefined : printCatalog.id,
        ...printCatalogOut
    }
}

export function mapDate(dateString: string): Date | undefined {
    return parseServerDateTime(dateString)
}

function mapTripleState(triState: boolean): string | undefined {
    switch (triState) {
        case null:
            return "na"
        case true:
            return "yes"
        case false:
            return "no"
    }
    return undefined
}

function removeTimezoneData(dateString: string): string {
    // Extract the ISO date string and the timezone
    const [isoDateString, timezone] = dateString.split("[")
    const dateWithoutTimezone = isoDateString.slice(0, -6) // Remove the +02:00 part for example
    const tz = timezone.slice(0, -1) // Remove the closing ]

    // Parse the date string with the specified timezone
    const dateInTimezone = moment.tz(dateWithoutTimezone, tz)

    // Convert the date to UTC
    const dateInUTC = dateInTimezone.utc()

    // Format the date to an ISO string in UTC
    const formattedUtcDate = dateInUTC.format()

    return formattedUtcDate
}

/*function mapDateIso(dateString: string): Date | undefined {
    return new Date(dateString)
}*/

function mapDisplayDateTimeOut(dateTime: Date | undefined | number): string {
    return formatDisplayDateTime(dateTime)
}

function mapDateTimeOut(dateTime: Date | undefined | number): string {
    return formatDateTime(dateTime)
}

function mapTripleStateOut(triState: string): boolean | null | undefined {
    switch (triState) {
        case "na":
            return null
        case "yes":
            return true
        case "no":
            return false
    }
    return undefined
}

function filterSynthetic(categories: Category[], refDataState: ReferenceDataState) {
    return categories.filter((category) => category && !refDataState.getCategory(category.id)?.synthetic)
}

function parseXmlAttribute(xmlElement: Element | undefined, attributeName: string): string {
    if (xmlElement) {
        return xmlElement.getAttribute(attributeName) || ""
    }

    // attribute or element not found
    return ""
}

export function parseUploadXml(xml: string): UploadResult | null {
    const parser = new DOMParser()
    const exhibitorXml = parser.parseFromString(xml, "text/xml")
    const uploadsXml = exhibitorXml.getElementsByTagName("upload")
    if (uploadsXml && uploadsXml.length >= 0) {
        return {
            fileId: parseXmlAttribute(uploadsXml[0], "fileId"),
            fileUrl: parseXmlAttribute(uploadsXml[0], "fileUrl"),
            fileName: parseXmlAttribute(uploadsXml[0], "fileName"),
            contentType: parseXmlAttribute(uploadsXml[0], "contentType"),
            size: parseXmlAttribute(uploadsXml[0], "size")
        }
    }
    return null
}

export function mockShowrooms(): Showrooms[] {
    return [
        {
            id: "showroom1",
            orderShowrooms: 0,
            title: branding.configuration.showroomsPage.sctShowrooms.titleMockShowroomEn,
            titleDe: branding.configuration.showroomsPage.sctShowrooms.titleMockShowroomDe,
            logourl: "/branding/applicationMedia/images/showroom_default_image.jpg",
            logourlDe: "/branding/applicationMedia/images/showroom_default_image.jpg",
            accessMethod: "Br",
            shortDescription: "",
            shortDescriptionDe: ""
        },
        {
            id: "showroom2",
            orderShowrooms: 1,
            title: branding.configuration.showroomsPage.sctShowrooms.titleMockShowroomEn,
            titleDe: branding.configuration.showroomsPage.sctShowrooms.titleMockShowroomDe,
            logourl: "/branding/applicationMedia/images/showroom_default_image.jpg",
            logourlDe: "/branding/applicationMedia/images/showroom_default_image.jpg",
            accessMethod: "Pb",
            shortDescription: "",
            shortDescriptionDe: ""
        }
    ]
}
